<template>
  <moe-page title="查看活动">
    <moe-form
      class="default-form"
      :showBack="false"
      :showClose="true">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>

        <moe-describe-list title="" :col="1">
          <moe-describe-item label="活动名称"> {{ goodsBadgeParams.name }} </moe-describe-item>
          <moe-describe-item label="使用时间">{{ goodsBadgeParams.startTime }} 至 {{ goodsBadgeParams.endTime }}</moe-describe-item>
          <moe-describe-item label="活动图标">
            <moe-image :src="goodsBadgeParams.cover" width="100px" height="100px"></moe-image>
          </moe-describe-item>
          <moe-describe-item label="活动图标位置">{{ goodsBadgeParams.style }}</moe-describe-item>
        </moe-describe-list>
      </div>

      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :numberShow="false"
          :data="goodsBadgeParams.configList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }">
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" prop="salePrice" min-width="150" :formatter="salePriceComputed" />
          <el-table-column label="库存" prop="stock" min-width="80" />
        </moe-table>
      </div>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGoodsBadgeDetail',
  data() {
    return {
      goodsBadgeParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        cover: '',
        style: '',
        configList: [],
      },
    }
  },
  methods: {
    salePriceComputed({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? ` - ¥ ${maxPrice}` : ''}`;
    },
    formatterStyle({ top, bottom }) {
      if (top === 0) return '上方'
      if (bottom === 0) return '下方'
    },
    /** 获取主图打标详情 */
    async req_getBuyGiftDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.GOODSBADGE_API.getGoodsBadgeDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { id, name, startTime, endTime, cover, style, configList } = result;
          this.goodsBadgeParams = {
            id,
            name,
            startTime,
            endTime,
            cover,
            style: this.formatterStyle(JSON.parse(style)),
            configList: []
          };

          this.getQueryShopGoodsByIds(configList);
        } else {
          this.$moe_msg.error(message);
        }
      }
    },
    /** 根据商品ID获取商品列表 */
    async getQueryShopGoodsByIds(goodsList) {
      const { code, result, message } = await this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goodsList.map(({ goodsId }) => goodsId).join(',') });
      if (code === 200) {
        this.goodsBadgeParams.configList = goodsList.map(({ goodsId, id }) => {
          let findGoodsItem = result.find(({ id }) => goodsId === id);
          return {
            ...findGoodsItem,
            goodsId: findGoodsItem.id,
            id,
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
  mounted() {
    this.req_getBuyGiftDetail();
  }
}
</script>

<style lang="scss">

</style>